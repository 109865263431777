



export default function Footer() {
    return(
        <div>
            <div className="footer">
                    <p>&copy; 2022 Doumbia CIV, All rights reserved</p>
            </div>
        </div>
    )
}